import React from "react"
import { Link } from "gatsby"

export default function Home() {
  return (
    <div>
      <h1>Hello learner, </h1>
      <p>
        Thanks for showing your interest in stepslabs. Unfortunately, this
        website is under development. Please write to us at
        harendra@stepslabs.com and we shall notify you when it's live. Thanks!!{" "}
      </p>
    </div>
  )
}
